import { useTitle } from 'react-use';

import { ChannelUtils } from '../../components/Channel';
import { makeTitle } from '../../utils/common';
import { useLeaderboardStore } from './Context';
import { GamePackDetails } from './GamePackDetails';
import {
  GlobalCompanyLeaderboardSection,
  GlobalLeaderboardSection,
  OrganizationLeaderboardSection,
} from './LeaderboardSection';
import { MyPairing } from './MyPairing';

const LEADERBOARD_LIMIT_SM = 9;
const LEADERBOARD_LIMIT_LG = 20;

export function LeaderboardHome(): JSX.Element {
  const state = useLeaderboardStore();
  const channelName = ChannelUtils.ChannelName(state.round?.channel);
  const groupName = channelName ?? state.round?.organization?.name;
  useTitle(makeTitle(groupName ? `${groupName} Leaderboard` : ''));

  if (!state.round) return <></>;

  return (
    <div className='w-full my-10'>
      <section className='mb-8 px-16'>
        <div className='flex items-center text-2xl'>
          <span className='text-secondary'>My Stats</span>
          <div className='text-secondary mx-2'>/</div>

          <div className='text-white font-bold'>Pair Round Detail</div>
        </div>
      </section>

      <section className='flex items-start justify-between gap-8 px-16'>
        <div className='w-1/2 mt-5'>
          <GamePackDetails
            gamePack={state.round.mainGamePack}
            playersCount={state.round.playersCount}
            channelName={channelName}
            startDate={state.round.startedAt}
            endDate={state.round.endedAt}
          />
        </div>

        <MyPairing />
      </section>

      <section className='mt-7.5 px-16'>
        <OrganizationLeaderboardSection
          limit={
            state.globalLeaderboardEnabled
              ? LEADERBOARD_LIMIT_SM
              : LEADERBOARD_LIMIT_LG
          }
        />
      </section>

      {state.globalLeaderboardEnabled && state.round.globalRoundId && (
        <section className='mt-10.5 px-16'>
          <GlobalLeaderboardSection limit={LEADERBOARD_LIMIT_SM} />
        </section>
      )}

      {state.globalLeaderboardEnabled && state.round.globalRoundId && (
        <section className='mt-10.5 px-16'>
          <GlobalCompanyLeaderboardSection />
        </section>
      )}
    </div>
  );
}
